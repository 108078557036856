// 生成UUID
const getUUID = () => {
  return 'xxxxxxxx-xxxx-4xxx-yxxx-xxxxxxxxxxxx'.replace(/[xy]/g, function (c) {
    let r = (Math.random() * 16) | 0, v = c == 'x' ? r : (r & 0x3) | 0x8
    return v.toString(16)
  })
}

// 解析Jwt
const parseJwt = (token = '')  => {
  try {
    var base64Url = token.split('.')[1]
    var base64 = base64Url.replace(/-/g, '+').replace(/_/g, '/')
    var jsonPayload = decodeURIComponent(atob(base64).split('').map(function (c) {
      return '%' + ('00' + c.charCodeAt(0).toString(16)).slice(-2)
    }).join(''))
    return JSON.parse(jsonPayload)
  } catch (err) {
    return {}
  }
}

export {
  getUUID,
  parseJwt
}